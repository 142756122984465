<template>
  <div class="service_xy">
    <div v-html="service"></div>
  </div>
</template>

<script>
import { text } from '@/components/Modal/ProtocolModal/protocolText'
export default {
  name: '',
  data() {
    return {
      service: '',
    }
  },
  created() {
    this.service = text.service
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.service_xy {
  background: #ffffff;
  div{
    width: 1200px;
    line-height: 30px;
    margin: 0 auto;
    ::v-deep p:nth-child(2) {
      text-align: center;
      font-weight: 700;
    }
  }
}
</style>
